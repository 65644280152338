import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardComponents.css';
import { useModels } from '../../context/ModelsContext';

const Overview = () => {
  const navigate = useNavigate();
  const { models, loading, error } = useModels();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Ensure models is an array before accessing length
  const modelsList = Array.isArray(models) ? models : [];

  const handleFineTuneClick = () => {
    navigate('/dashboard/fine-tune');
  };

  const handleMultiFileFineTuneClick = () => {
    navigate('/dashboard/multi-file-fine-tune');
  };

  const handleModelStatsClick = () => {
    navigate('/dashboard/models');
  };

  const formatTime = (isoString) => {
    const date = new Date(isoString);
    const now = new Date();
    const diffInMinutes = Math.floor((now - date) / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInMinutes < 1) return 'Just now';
    if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
    if (diffInHours < 24) return `${diffInHours}h ago`;
    if (diffInDays === 1) return 'Yesterday';
    
    return date.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="dashboard-page">
      <div className="page-header">
        <h1>Fine Tune</h1>
        <p>Welcome to TagflowAI Fine Tuning Console</p>
      </div>
      <div className="stats-grid">
        <div className="stat-box" onClick={handleModelStatsClick}>
          <div className="action-box">
            <div className="action-content">
              <h3>Active Models</h3>
              <h2 className="stat-number">{modelsList.length}</h2>
            </div>
          </div>
        </div>
        
        <div className="stat-box" onClick={handleFineTuneClick}>
          <div className="action-box">
            <div className="action-content">
              <h3>JSONL Fine-Tuning</h3>
              <p>Create and deploy a fine-tuned model from your own .jsonl data.</p>
            </div>
            <div className="arrow-icon">→</div>
          </div>
        </div>
        
        <div className="stat-box" onClick={handleMultiFileFineTuneClick}>
          <div className="action-box">
            <div className="action-content">
              <h3>File Fine-Tuning</h3>
              <p>Create and deploy a fine-tuned model from your own pdf, image, or text data. (beta)</p>
            </div>
            <div className="arrow-icon">→</div>
          </div>
        </div>
      </div>
      <div className="recent-activity">
        <h2>Recent Activity</h2>
        <div className="activity-list">
          {modelsList.length > 0 ? (
            modelsList.map((activity, index) => (
              <div key={index} className="activity-item">
                <span className="activity-time">{formatTime(activity.time)}</span>
                <span className="activity-text">{activity.text}</span>
              </div>
            ))
          ) : (
            <div className="activity-item">
              <span className="activity-text">No recent activity</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Overview;