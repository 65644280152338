import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardComponents.css';
import { useModels } from '../../context/ModelsContext';

const Models = () => {
  const navigate = useNavigate();
  const { models, loading, error } = useModels();
  
  if (loading) {
    return (
      <div className="dashboard-page">
        <div className="page-header">
          <h1>Models</h1>
          <p>Loading your models...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="dashboard-page">
        <div className="page-header">
          <h1>Models</h1>
          <p className="error-message">Error loading models: {error}</p>
        </div>
      </div>
    );
  }
  
  return (
    <div className="dashboard-page">
      <div className="page-header">
        <h1>Models</h1>
        <p>Manage your fine-tuned models</p>
      </div>
      <div className="models-grid">
        {models.map((model) => (
          <div 
            key={model.id}
            className="model-card"
            onClick={() => navigate(`/dashboard/models/${model.id}`)}
            style={{ cursor: 'pointer' }}
          >
            <div className="model-header">
              <h3>{model.name}</h3>
              <span className={`model-status deployed`}>
                DEPLOYED
              </span>
            </div>
            <p className="model-description">{model.persona.substring(0, 100)}...</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Models;
