//import { v4 as uuidv4 } from 'uuid';

// Initialize API keys from environment variables
const initializeApiKeys = () => {
  const storedApiKeys = localStorage.getItem('apiKeys');
  if (!storedApiKeys) {
    const apiKeys = {
      'fintelite_user@email.com': process.env.REACT_APP_API_KEY_FINTELITE_USER || 'sk-tagflow-xxxx',
      'test_user@email.com': process.env.REACT_APP_API_KEY_TEST_USER || 'sk-tagflow-xxxx',
      'fajarluhung@sribuu.id': process.env.REACT_APP_API_KEY_FAJAR || 'sk-tagflow-xxxx',
      'arief.wijaya@fintelite.ai': process.env.REACT_APP_API_KEY_ARIEF || 'sk-tagflow-xxxx',
      'martin@fintelite.ai': process.env.REACT_APP_API_KEY_MARTIN || 'sk-tagflow-xxxx',
      'nadia@sribuu.id': process.env.REACT_APP_API_KEY_NADIA || 'sk-tagflow-xxxx',
      'erwin@raigine.com': process.env.REACT_APP_API_KEY_ERWIN || 'sk-tagflow-xxxx',
      'faristama@raigine.com': process.env.REACT_APP_API_KEY_FARISTAMA || 'sk-tagflow-xxxx',
      'febri@raigine.com': process.env.REACT_APP_API_KEY_FEBRI || 'sk-tagflow-xxxx',
      'muh.aznan@gmail.com': process.env.REACT_APP_API_KEY_AZNAN || 'sk-tagflow-xxxx',
      'arrival@arlabsgroup.com': process.env.REACT_APP_API_KEY_ARRIVAL || 'sk-tagflow-xxxx',
      'arief.rachman@rainmaker.co.id': process.env.REACT_APP_API_KEY_ARIEF_RACHMAN || 'sk-tagflow-xxxx',
      'deby.kasenda@rainmaker.co.id': process.env.REACT_APP_API_KEY_DEBY || 'sk-tagflow-xxxx',
      'ikhwan.tauhid@gmail.com': process.env.REACT_APP_API_KEY_IKHWAN || 'sk-tagflow-xxxx',
      'bernard@race.capital': process.env.REACT_APP_API_KEY_BERNARD || 'sk-tagflow-xxxx',
      'mohak@race.capital': process.env.REACT_APP_API_KEY_MOHAK || 'sk-tagflow-xxxx',
      'product@hulutalent.id': process.env.REACT_APP_API_KEY_HULUTALENT || 'sk-tagflow-xxxx',
      'mujadid10@gmail.com': process.env.REACT_APP_API_KEY_MUJADID || 'sk-tagflow-xxxx',
    };
    localStorage.setItem('apiKeys', JSON.stringify(apiKeys));
    return apiKeys;
  }

  return JSON.parse(storedApiKeys);
};

const apiKeys = initializeApiKeys();

const VALID_CREDENTIALS = [
  {
    username: process.env.REACT_APP_USER1_EMAIL,
    password: process.env.REACT_APP_USER1_PASSWORD,
    apiKey: apiKeys[process.env.REACT_APP_USER1_EMAIL]
  },
  {
    username: process.env.REACT_APP_USER2_EMAIL,
    password: process.env.REACT_APP_USER2_PASSWORD,
    apiKey: apiKeys[process.env.REACT_APP_USER2_EMAIL]
  },
  {
    username: 'fajarluhung@sribuu.id',
    password: process.env.REACT_APP_PASSWORD_FINTELITE,
    apiKey: apiKeys['fajarluhung@sribuu.id']
  },
  {
    username: 'arief.wijaya@fintelite.ai',
    password: process.env.REACT_APP_PASSWORD_FINTELITE,
    apiKey: apiKeys['arief.wijaya@fintelite.ai']
  },
  {
    username: 'martin@fintelite.ai',
    password: process.env.REACT_APP_PASSWORD_FINTELITE,
    apiKey: apiKeys['martin@fintelite.ai']
  },
  {
    username: 'nadia@sribuu.id',
    password: process.env.REACT_APP_PASSWORD_FINTELITE,
    apiKey: apiKeys['nadia@sribuu.id']
  },
  {
    username: 'erwin@raigine.com',
    password: process.env.REACT_APP_PASSWORD_RAIGINE,
    apiKey: apiKeys['erwin@raigine.com']
  },
  {
    username: 'faristama@raigine.com',
    password: process.env.REACT_APP_PASSWORD_RAIGINE,
    apiKey: apiKeys['faristama@raigine.com']
  },
  {
    username: 'febri@raigine.com',
    password: process.env.REACT_APP_PASSWORD_RAIGINE,
    apiKey: apiKeys['febri@raigine.com']
  },
  {
    username: 'muh.aznan@gmail.com',
    password: process.env.REACT_APP_PASSWORD_CEPATDOK,
    apiKey: apiKeys['muh.aznan@gmail.com']
  },
  {
    username: 'arrival@arlabsgroup.com',
    password: process.env.REACT_APP_PASSWORD_ARTON,
    apiKey: apiKeys['arrival@arlabsgroup.com']
  },
  {
    username: 'arief.rachman@rainmaker.co.id',
    password: process.env.REACT_APP_PASSWORD_RAINMAKER,
    apiKey: apiKeys['arief.rachman@rainmaker.co.id']
  },
  {
    username: 'deby.kasenda@rainmaker.co.id',
    password: process.env.REACT_APP_PASSWORD_RAINMAKER,
    apiKey: apiKeys['deby.kasenda@rainmaker.co.id']
  },
  {
    username: 'ikhwan.tauhid@gmail.com',
    password: process.env.REACT_APP_PASSWORD_RAINMAKER,
    apiKey: apiKeys['ikhwan.tauhid@gmail.com']
  },
  {
    username: 'bernard@race.capital',
    password: process.env.REACT_APP_PASSWORD_RACE_CAPITAL,
    apiKey: apiKeys['bernard@race.capital']
  },
  {
    username: 'mohak@race.capital',
    password: process.env.REACT_APP_PASSWORD_RACE_CAPITAL,
    apiKey: apiKeys['mohak@race.capital']
  },
  {
    username: 'mujadid10@gmail.com',
    password: process.env.REACT_APP_PASSWORD_FAIZ,
    apiKey: apiKeys['mujadid10@gmail.com']
  },
  {
    username: 'product@hulutalent.id',
    password: process.env.REACT_APP_PASSWORD_HULUTALENT,
    apiKey: apiKeys['product@hulutalent.id']
  }
];

export const authenticate = (username, password) => {
  return VALID_CREDENTIALS.some(
    cred => cred.username === username && cred.password === password
  );
};

export const setAuthToken = (username) => {
  const user = VALID_CREDENTIALS.find(cred => cred.username === username);
  if (!user) {
    console.error('User not found:', username);
    return;
  }
  
  localStorage.setItem('isAuthenticated', 'true');
  localStorage.setItem('currentUser', username);
  localStorage.setItem('apiKey', user.apiKey);
  console.log('API Key set:', user.apiKey); // Debug log
};

export const clearAuthToken = () => {
  localStorage.removeItem('isAuthenticated');
  localStorage.removeItem('currentUser');
  localStorage.removeItem('apiKey');
};

export const isAuthenticated = () => {
  return localStorage.getItem('isAuthenticated') === 'true';
};

export const getCurrentUser = () => {
  return localStorage.getItem('currentUser');
};

export const getCurrentApiKey = () => {
  const apiKey = localStorage.getItem('apiKey');
  if (!apiKey) {
    const currentUser = getCurrentUser();
    if (currentUser) {
      const user = VALID_CREDENTIALS.find(cred => cred.username === currentUser);
      if (user) {
        localStorage.setItem('apiKey', user.apiKey);
        return user.apiKey;
      }
    }
  }
  return apiKey;
};

