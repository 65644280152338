import emailjs from '@emailjs/browser';
import JSZip from 'jszip';

emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

// New function for contact form
export const sendContactEmail = async (formData) => {
  try {
    const templateParams = {
      name: formData.name,
      email: formData.email,
      company: formData.company,
      message: formData.message || 'No message provided'
    };

    const response = await emailjs.send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID_CONTACT_US,
      templateParams
    );

    return response.status === 200;
  } catch (error) {
    console.error('Contact form submission failed:', error);
    throw error;
  }
};

export const sendNotificationEmail = async (action, details) => {
  try {
    let finalFile;
    
    // Handle both single file and multiple files scenarios
    if (details.files && details.files.length > 0) {
      // MultiFileFineTune.js case
      if (details.files.length === 1) {
        finalFile = details.files[0];
      } else {
        switch (details.fileType) {
          case 'pdf':
            finalFile = details.file; // PDF combination is handled in MultiFileFineTune.js
            break;
            
          case 'image':
            const zip = new JSZip();
            for (let i = 0; i < details.files.length; i++) {
              await zip.file(`image_${i + 1}${getFileExtension(details.files[i].name)}`, details.files[i]);
            }
            const zipContent = await zip.generateAsync({ type: 'blob' });
            finalFile = new File([zipContent], 'combined_images.zip', { type: 'application/zip' });
            break;
            
          case 'text':
            const textContents = await Promise.all(
              details.files.map(async (file) => {
                const text = await file.text();
                return `=== ${file.name} ===\n${text}\n\n`;
              })
            );
            const combinedText = textContents.join('');
            finalFile = new File([combinedText], 'combined_text.txt', { type: 'text/plain' });
            break;
            
          default:
            throw new Error('Unsupported file type');
        }
      }
    } else {
      // FineTune.js case (single JSONL file)
      finalFile = details.file;
      if (!finalFile) {
        throw new Error('No file provided');
      }
    }

    // Convert final file to base64
    const reader = new FileReader();
    const base64File = await new Promise((resolve, reject) => {
      if (!finalFile) {
        reject(new Error('No file to process'));
        return;
      }
      reader.onload = () => {
        const base64 = reader.result.split(',')[1];
        resolve(base64);
      };
      reader.onerror = reject;
      reader.readAsDataURL(new Blob([finalFile], { type: finalFile.type }));
    });

    const fileTypeDisplay = {
      'pdf': 'PDF',
      'image': 'Image',
      'text': 'Text',
      'jsonl': 'JSONL'
    };

    // Prepare email template parameters
    const templateParams = {
      to_name: details.userEmail,
      from_name: 'TagFlow AI',
      message: `
        Model Name: ${details.modelName}
        AI Persona: ${details.aiPersona}
        Base Model: ${details.baseModel}
        File Type: ${fileTypeDisplay[details.fileType] || 'JSONL'}
        ${details.inputFormat ? `Input Format: ${details.inputFormat}` : ''}
        ${details.outputFormat ? `Output Format: ${details.outputFormat}` : ''}
        ${details.examples ? `Examples: ${JSON.stringify(details.examples, null, 2)}` : ''}
        ${details.learningRate ? `Learning Rate: ${details.learningRate}` : ''}
        ${details.epochs ? `Epochs: ${details.epochs}` : ''}
        ${details.trainTestSplit ? `Train-Test Split: ${details.trainTestSplit}%` : ''}
      `,
      subject: action,
      attachments: base64File,
      name: finalFile.name
    };

    const response = await emailjs.send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      templateParams
    );

    return response.status === 200;
  } catch (error) {
    console.error('File notification failed:', error);
    throw error;
  }
};

// Helper function to get file extension
const getFileExtension = (filename) => {
  return filename.substring(filename.lastIndexOf('.'));
};

