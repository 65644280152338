import React from 'react';
import './GetStarted.css';
import deepseekLogo from '../assets/images/deepseek.png';
import qwenLogo from '../assets/images/qwen.png';
import llamaLogo from '../assets/images/llama2.png';
import mistralLogo from '../assets/images/mistral.jpg';
import plusLogo from '../assets/images/plus.png';

const GetStarted = () => {
  const models = [
    { name: 'Mixtral 8x7B', type: 'Chat', logo: mistralLogo },
    { name: 'Llama 3.1 8B', type: 'Chat', logo: llamaLogo },
    { name: 'Llama 3.1 70B', type: 'Chat', logo: llamaLogo },
    { name: 'DeepSeek R1', type: 'Chat', logo: deepseekLogo },
    { name: 'Qwen 2.5', type: 'Chat', logo: qwenLogo },
    { name: 'Many more!', type: '10+ LLM Supported', logo: plusLogo }
  ];

  return (
    <section className="get-started" id="get-started">
      <div className="get-started-content">
        <h2>Build on top of the best <span className="highlight">Open Source LLM</span> models</h2>
        <p>Customize your AI models, seamlessly integrate with API's, and download your own weigths.</p>
        
        <div className="models-grid">
          {models.map((model, index) => (
            <div key={index} className="model-box">
              <img src={model.logo} alt={model.name} className="model-logo" />
              <h3>{model.name}</h3>
              <span>{model.type}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default GetStarted;

