import React, { useState } from 'react';
import './Blog.css';
import antlerLogo from '../assets/images/antler.svg';
import nvidiaLogo from '../assets/images/nvidia-inception-logo.webp';
import tagflowLogo from '../assets/images/logo.png';
import linkedinIcon from '../assets/images/linkedin.png';
import { sendContactEmail } from '../services/emailServices';

const Blog = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const templateParams = {
        name: formData.name,
        email: formData.email,
        company: formData.company,
        message: formData.message
      };

      const success = await sendContactEmail(templateParams);
      
      if (success) {
        setSubmitStatus('success');
        setFormData({
          name: '',
          email: '',
          company: '',
          message: ''
        });
      }
    } catch (error) {
      console.error('Failed to send email:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <section className="blog" id="blog">
        <div className="demo-content">
          <h2>Connect with the TagflowAI Team</h2>
          <p className="demo-description">
            Discover how our custom-built AI solutions can transform your operations 
            and elevate customer interactions with a live demo. With our team, you'll 
            experience firsthand how seamless data labeling, model fine-tuning, and 
            synthetic data generation can be when powered by intelligent automation.
          </p>
        </div>
        
        <div className="demo-form-container">
          <form className="demo-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Work Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="company"
                placeholder="Company Name"
                value={formData.company}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <textarea
                name="message"
                placeholder="Tell us about your use case (Optional)"
                value={formData.message}
                onChange={handleChange}
                rows="4"
              />
            </div>
            <button 
              type="submit" 
              className="demo-submit" 
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Submit Form'}
            </button>

            {submitStatus === 'success' && (
              <div className="submit-message success">
                Thank you for your message. We'll be in touch soon!
              </div>
            )}
            
            {submitStatus === 'error' && (
              <div className="submit-message error">
                Sorry, there was an error sending your message. Please try again later.
              </div>
            )}
          </form>
        </div>
      </section>
      <div className="footer-section">
        <div className="company-info">
          <img 
            src={tagflowLogo}
            alt="TagflowAI Logo" 
            className="company-logo"
          />
          <div className="company-details">
            <span className="company-name">TagflowAI, Inc</span>
            <span className="company-location">San Francisco, CA</span>
            <div className="social-links">
              <span className="follow-text">Follow us</span>
              <a href="https://www.linkedin.com/company/tagflow-ai/" target="_blank" rel="noopener noreferrer">
                <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
              </a>
            </div>
          </div>
        </div>
        
        <div className="partners-section">
          <span className="partners-title">Our Partners</span>
          <div className="partner-logos">
            <img 
              src={antlerLogo} 
              alt="Antler Logo" 
              className="partner-logo"
            />
            <img 
              src={nvidiaLogo} 
              alt="NVIDIA Inception Logo" 
              className="partner-logo nvidia-logo"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;


