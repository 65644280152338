import React, { useState, useEffect } from 'react';
import { getCurrentApiKey, getCurrentUser } from '../../auth';
import './DashboardComponents.css';

const ApiKeys = () => {
  const [showKey, setShowKey] = useState(false);
  const [apiKey, setApiKey] = useState('');

  useEffect(() => {
    const currentUser = getCurrentUser();
    const key = getCurrentApiKey();

    if (key) {
      setApiKey(key);
    } else {
      console.error('No API key found for user:', currentUser);
    }
  }, []);

  const copyToClipboard = () => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
      alert('API key copied to clipboard!');
    }
  };

  return (
    <div className="dashboard-page">
      <div className="page-header">
        <h1>API Keys</h1>
        <p>Manage your API keys for TagflowAI services</p>
      </div>
      <div className="api-keys-section">
        <div className="api-key-card">
          <div className="api-key-header">
            <h3>Production API Key</h3>
            <button 
              className="toggle-key-btn"
              onClick={() => setShowKey(!showKey)}
              disabled={!apiKey}
            >
              {showKey ? 'Hide' : 'Show'}
            </button>
          </div>
          <div className="api-key-content">
            <code className="api-key-value">
              {apiKey ? (showKey ? apiKey : '••••••••••••••••••••••••••••••••') : 'No API key available'}
            </code>
            <button className="copy-btn" onClick={copyToClipboard} disabled={!apiKey}>
              Copy
            </button>
          </div>
          <p className="api-key-warning">
            Keep your API key secure and never share it publicly
          </p>
        </div>
      </div>
    </div>
  );
};

export default ApiKeys;