import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/images/logo.png';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="TagflowAI Logo" />
        <strong>TagflowAI</strong>
      </div>
      <nav>
        <ul>
          {/* <li><a href="#use-cases">Use Cases</a></li>
          <li><a href="#get-started">Get Started</a></li>
          <li><a href="#blog">Blog</a></li>
          <li><a href="#about-us">About Us</a></li> */}
          <li>
            <Link to="/login">
              <button className="login-btn">Login</button>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;