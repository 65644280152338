import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCurrentUser } from '../auth';

const ModelsContext = createContext();

export const ModelsProvider = ({ children }) => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        if (!process.env.REACT_APP_ADMIN_KEY) {
          throw new Error('API key not configured');
        }

        const response = await fetch('https://api.tagflow-ai.com/api/v1/models', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Admin-Key': process.env.REACT_APP_ADMIN_KEY,
            'Origin': window.location.origin
          },
          mode: 'cors',
          credentials: 'omit'
        });

        if (!response.ok) {
          const errorData = await response.text();
          console.error('API Response:', errorData);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('API Response:', data); // Debug log
        
        if (!data.models) {
          throw new Error('Invalid response format');
        }

        const currentUser = getCurrentUser();
        console.log('Current User:', currentUser); // Debug log
        
        const transformedModels = data.models
          .filter(model => model.targetUser.toLowerCase() === currentUser.toLowerCase())
          .map(model => ({
            id: model.timestamp,
            name: model.modelName,
            status: 'READY',
            time: model.timestamp,
            text: `Model "${model.modelName}" was created`,
            provider: model.provider,
            baseModel: model.baseModel,
            persona: model.aiPersona,
            userEmail: model.targetUser,
            dataset: {
              trainingSize: model.trainingSetSize,
              testSize: model.testSetSize,
              content: model.fileContent || 'No dataset content available'
            },
            trainingConfig: {
              learningRate: model.learningRate,
              numEpochs: model.trainingEpochs
            },
            createdAt: new Date(model.timestamp).toLocaleString(),
            apiEndpoint: `tagflow:${model.modelName.toLowerCase()}`
          }));

        console.log('Transformed Models:', transformedModels); // Debug log
        setModels(transformedModels);
      } catch (err) {
        console.error('Error fetching models:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchModels();
  }, []);

  return (
    <ModelsContext.Provider value={{ models, loading, error }}>
      {children}
    </ModelsContext.Provider>
  );
};

export const useModels = () => {
  const context = useContext(ModelsContext);
  if (!context) {
    throw new Error('useModels must be used within a ModelsProvider');
  }
  return context;
};

